function addSmoothEvent(anchor) {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();

    const id =
      anchor.getAttribute('href') || anchor.getAttribute('data-anchor');
    const item = document.querySelector(id) || document.getElementById(id);
    item.scrollIntoView({
      behavior: 'smooth',
    });
  });
}

const checkAnchors = () => {
  let dataAnchor = document.querySelectorAll(
    '[data-anchor]:not([data-anchor=""])'
  );
  dataAnchor.forEach((anchor) => addSmoothEvent(anchor));
};

window.addEventListener('load', checkAnchors);

setTimeout(() => {
/* eslint-disable */
let fabCheckbox = document.getElementById('fabCheckbox');
let fabClose = document.getElementById('Fab-close');
let fabCheckboxHelp = document.getElementById('fabCheckbox-help');
let fabActionPhone = document.getElementById('Fab-action-phone');
let fabContact = document.getElementById('Fab-contact');
let fabChat = document.getElementById('Fab-chat');
// let fabWhatsapp = document.getElementById('Fab-action-whatsapp');
let chatIframe = document.getElementById('Fab-iframe-chat');
let wrapperIframe = document.getElementById('Fab-iframe-wrapper');
// let btnChatClose = '';

// function aguardarTagCarregar() {
//   console.log('chegou aqui')
//   var elemento = document.querySelector('.sigma-btn-close'); // Procura o elemento pela tag
//   console.log(elemento)

//   if (elemento?.length > 0) {
//     console.log('o botão foi carregado', elemento)
//   } else {
//     setTimeout(function() {
//       aguardarTagCarregar(); // Verifica novamente após um intervalo de tempo
//     }, 500); // Intervalo de tempo em milissegundos (aqui, 100ms)
//   }
// }

// aguardarTagCarregar()

if (fabCheckbox) {
  fabCheckbox.checked = false;

  fabCheckbox.addEventListener('click', function () {
    wrapperIframe.style.display = 'none';

    if (this.checked) {
      fabCheckboxHelp.style.display = 'none';
      fabChat.style.display = 'block';
      fabActionPhone.style.display = 'block';
      // fabWhatsapp.style.display = 'block';
    }
  });
}

if (fabClose) {
  fabClose.addEventListener('click', function () {
    fabCheckboxHelp.style.display = 'none';
  });
}

if (fabActionPhone) {
  fabActionPhone.addEventListener('click', function () {
    if (fabContact) {
      fabContact.classList.toggle('hidden');
    }
  });
}

if (fabChat) {
  fabChat.addEventListener('click', function () {
    // if (chatIframe) {
    //   chatIframe.style.display = 'block';
    //   fabContact.classList.remove('hidden');
    // }
    fabCheckbox.checked = false;
    wrapperIframe.style.display = 'none';
    fabChat.style.display = 'none';
    fabActionPhone.style.display = 'none';
    // fabWhatsapp.style.display = 'none';
    chatIframe.style.display = 'none';
    fabContact.classList.add('hidden');
    fabCheckboxHelp.style.display = 'none';
  });
}

// if (btnChatClose) {
//   btnChatClose.addEventListener('click', function () {
//     chatIframe.style.display = 'none';
//     wrapperIframe.style.display = 'none';
//     fabContact.classList.add('hidden');
//     fabChat.style.display = 'block';
//     fabActionPhone.style.display = 'block';
//     fabWhatsapp.style.display = 'block';
//     console.log('teste')
//   });
// }
}, 2000);

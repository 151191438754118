// Avoid some repetitive blocks

function checkDuplicates() {
  const blockSelectors = ['.Questions', '.ContactUs'];

  blockSelectors.forEach((selector) => {
    const blocks = document.querySelectorAll(`body > ${selector}`);
    const thereIsTabs = document.querySelectorAll(`.Tab-content ${selector}`);
    if (thereIsTabs) {
      Array.from(blocks).forEach((block) => {
        block.remove();
      });
    }
  });
}

checkDuplicates();

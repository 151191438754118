/* eslint-disable */
import { disableScroll, enableScroll } from './scroll';

const button = document.querySelector('.Header-city');
const overlay = document.querySelector('.Modal-overlay');
const body = document.querySelector('.Modal');
const formLocation = document.querySelector('#formLocationButton');
const closeModal = document.querySelector('#close-modal');

function setCookieCity(cityId, cityName) {
  document.cookie = 'PlanCity=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
  document.cookie = 'PlanType=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
  
  // data layer chosenCity
  dataLayer.push({
    event: 'chosenCity',
    city: cityName
  })

  let date = new Date();
  date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * 30);
  const expires = 'expires=' + date.toUTCString();
  cityName = encodeURI(cityName);
  document.cookie = 'PlanCity=' + cityId + '; ' + expires + '; path=/';
  document.cookie = 'PlanName=' + cityName + '; ' + expires + '; path=/';
  document.cookie = 'PlanType=PF; ' + expires + '; path=/';
  enableScroll();
}

if (closeModal) {
  closeModal.addEventListener('click', () => {
    body.classList.remove('Modal-show');
    jQuery('body').addClass('scroll-lock');
    enableScroll();
  });
}

// Ao selecionar a cidade e apertar em ok, o datalayer seleciona a cidade
formLocation.addEventListener(
  'click',
  function (event) {
    event.preventDefault();

    setCookieCity(
      jQuery('.select').select2('data')[0].title,
      jQuery('.select').select2('data')[0].id
    );
    document.location.reload();
  },
  true
);

button.addEventListener('click', function () {
  body.classList.add('Modal-show');
});

// overlay.addEventListener('click', function() {
//   body.classList.remove('Modal-show');
// });

window.addEventListener(
  'load',
  function (event) {
    if (window.document.cookie.indexOf('PlanCity=') == -1) {
      body.classList.add('Modal-show');
      disableScroll();
      return;
    }

    enableScroll();
  },
  true
);

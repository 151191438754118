const button = jQuery('.ModalBill-open');
const closeButton = jQuery('.ModalBill-close, .ModalBill-overlay');

const modalForm = jQuery('.ModalForm');
const buttonForm = jQuery('.ModalForm-open');
const closeButtonForm = jQuery('.ModalForm-close, .ModalForm-overlay');

const recaptcha = jQuery('#divRecaptcha');
const buttonGetBoletos = jQuery('#getBoletos');
const buttonSendBoletos = jQuery('#sendBoletos');
const buttonPrintBoletos = jQuery('#printBoletos');
const modal = jQuery('.ModalBill');
const modalButtons = jQuery('.Modal-groupButtons');
const modalError = jQuery('.Modal-error');
const modalMessage = jQuery('.Modal-message');
const modalTickets = jQuery('.Modal-tickets');
const modalFirst = jQuery('.Modal-first');
const modalSecond = jQuery('.Modal-second');
const modalSuccess = jQuery('.Modal-success');
const modalErrorStep = jQuery('.Modal-errorStep');
const modalSuccessText = jQuery('.ModalBill-success-title');
const modalErrorTitle = jQuery('.ModalBill-error-title');
const modalErrorMessage = jQuery('.ModalBill-error-message');
const modalTitle = jQuery('.ModalBill-title');
const modalSwipeBtn = jQuery('.ModalBill-wrapButtons');
const titleHide = jQuery('.ModalBill-titleHide');
let countBills = 0;

button.on('click', function () {
  jQuery('#cpfCnpj').val('');
  modal.addClass('ModalBill-show');
});

buttonForm.on('click', function () {
  modalForm.addClass('ModalForm-show');
});

closeButton.on('click', closeModalBills);

closeButtonForm.on('click', function () {
  modalForm.removeClass('ModalForm-show');
});

buttonGetBoletos.on('click', (event) => {
  event.preventDefault();
  const cpfCnpj = jQuery('#cpfCnpj');
  modalButtons.addClass('d-none');
  modalError.addClass('d-none');
  modalTickets.addClass('d-none');

  /* set text as loading */
  modalMessage.text('Procurando boletos.');
  modal.addClass('Modal-spin');
  const messageLoad = setInterval(() => {
    if (modalMessage.text().includes('...')) {
      modalMessage.text('Procurando boletos.');
    } else {
      modalMessage.text(modalMessage.text() + '.');
      setTimeout(() => {
        modal.removeClass('Modal-spin');
      }, 500);
    }
  }, 500);

  const settings = {
    url: ajax_object.xhr_url,
    method: 'POST',
    data: {
      action: 'get_tickets',
      nonce: ajax_object.sumicity_nonce,
      cpf: cpfCnpj.val().replace(/[^\d]+/g, ''),
    },
  };

  jQuery.ajax(settings).always((response) => {
    clearInterval(messageLoad);

    if (response.status !== 200) {
      modalError.text('Motivo: ' + response.responseJSON.data.error);
      modalError.removeClass('d-none');
      modalMessage.addClass('d-none');
    } else {
      if (response.boletos && Array.isArray(response.boletos)) {
        let ticketsHtml = '';

        if (response.error) {
          console.log('teste');
          modalFirst.addClass('d-none');
          modalMessage.addClass('d-none');
          modalTickets.addClass('d-none');
          modalErrorTitle
            .html(
              'Se você tem alguma fatura em aberto, acesse a central do Assinante!'
            )
            .addClass(
              'Modal-message Modal-title Modal-title-center ModalBill-title ModalBill-finish'
            );
          modalErrorTitle.removeClass('ModalBill-finish--error d-none');
          modalErrorMessage.html('');
          modalErrorStep.removeClass('d-none');
          return;
        }

        countBills = response.boletos.length;
        modalMessage.text(response.message);
        buttonPrintBoletos.attr(
          'href',
          `/wp-json/sc/v1/generate-pdf?cpf_cnpj=${jQuery(
            '#cpfCnpj'
          ).val()}&nonce=${ajax_object.sumicity_nonce}`
        );

        response.boletos.forEach((boleto, boletoIndex) => {
          if (boletoIndex > 0) {
            ticketsHtml += '';
          }
          
          // Reduzindo 1 dia da data para o formato correto
          let dateTicket = new Date(boleto.vencimento)
          dateTicket.setDate(dateTicket.getDate() - 1);
          dateTicket = dateTicket.toISOString().split('T')[0];

          modalMessage
            .removeClass('Modal-error d-none')
            .addClass(
              'Modal-message Modal-title Modal-title-center ModalBill-title'
            )
            .show();

          ticketsHtml += `
            <dt>Data de Vencimento:</dt>
            <dd>${dateTicket}</dd>
            <dt>Valor:</dt>
            <dd>${boleto.valor}</dd>
            <dt>Linha digitável:</dt>
            <dd>${boleto.linhaDigitavel}</dd>
            <p>Imprima este boleto para pagar em casas lotéricas.</p>
            <ul class="ModalBill-head">
              <li>
                Giga+ Fibra
              </li>
              <li>
                VALOR
                <cite>${boleto.valor}</cite>
              </li>
              <li>
                Vencimento
                <cite>${dateTicket}</cite>
              </li>
            </ul>
            <dd style="text-align: center;"><img src="${boleto.codigoBarrasImg}" alt="Código de barras" /></dd>
            <hr />
            `;
        });

        modalTickets.html(ticketsHtml);
        modalTickets.removeClass('d-none');
        modalButtons.removeClass('d-none');
        modalSwipeBtn.removeClass('d-none');
        titleHide.addClass('d-none');
        modalFirst.addClass('d-none');
      } else {
        titleHide.addClass('d-none');
        modal.removeClass('Modal-spin');
        modalFirst.addClass('d-none');
        modalSecond.addClass('d-none');
        modalMessage.addClass('d-none');
        modalTickets.addClass('d-none');
        modalErrorTitle
          .html(
            'Se você tem alguma fatura em aberto, acesse a central do Assinante!'
          )
          .addClass(
            'Modal-message Modal-title Modal-title-center ModalBill-title ModalBill-finish'
          );
        modalErrorTitle.removeClass('ModalBill-finish--error d-none');
        modalErrorMessage.html('');
        modalErrorStep.removeClass('d-none');
        return;
      }
    }
    modalSecond.removeClass('d-none');
  });
});

buttonSendBoletos.on('click', (event) => {
  event.preventDefault();
  const cpfCnpj = jQuery('#cpfCnpj');

  const settings = {
    url: ajax_object.xhr_url,
    method: 'POST',
    data: {
      action: 'get_duplicate_tickets',
      nonce: ajax_object.sumicity_nonce,
      cpf: cpfCnpj.val().replace(/[^\d]+/g, ''),
    },
  };

  jQuery.ajax(settings).always((response) => {
    if (response.error) {
      modalErrorTitle.addClass('ModalBill-finish--error');
      modalErrorTitle
        .html('Ocorreu um erro!')
        .addClass(
          'Modal-message Modal-title Modal-title-center ModalBill-title'
        )
        .removeClass('d-none');

      const htmlError = `
      <p>Houve um erro inesperado e não foi concluido a sua solicitação</p>
      <p>Tente novamente um pouco mais tarde ou se preferir ligue na central de atendimento <strong>10353</strong>, atendemos todos os dias, 24 horas.</p>
      <p class="Modal-error">Motivo: ${response.message}</p>
      `;

      modalSecond.addClass('d-none');
      modalErrorMessage.html(htmlError);
      modalErrorStep.removeClass('d-none');

      return;
    }

    modalMessage.addClass('d-none');
    modalSuccessText.addClass('ModalBill-finish');
    modalSuccessText
      .html(response.boletos.length == undefined ?'Enviando boletos...' : `${response.boletos.length} boleto(s) enviado(s) com sucesso!`)
      .addClass(
        'Modal-message Modal-title Modal-title-center ModalBill-title Modal-title-spaceTop'
      )
      .removeClass('d-none');

    modalSwipeBtn.addClass('d-none');
    modalSecond.addClass('d-none');
    modalSuccess.removeClass('d-none');
  });
});

function closeModalBills() {
  modal.removeClass('ModalBill-show');
  modalFirst.removeClass('d-none');
  modalTitle.html('Boleto Rápido');
  modalTitle.removeClass('d-none');
  modalButtons.addClass('d-none');
  modalError.addClass('d-none');
  modalTickets.removeClass(
    'ModalBill-finish Modal-message Modal-title Modal-title-center ModalBill-title Modal-title-spaceTop'
  );
  modalTickets.addClass('d-none');
  modalSecond.addClass('d-none');
  modalSuccess.addClass('d-none');
  modalErrorStep.addClass('d-none');
}

window.closeModalBills = closeModalBills;

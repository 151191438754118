function contentChanged() {
  const selection =
    window.document.querySelector('span.vpw-loading-dictionary') !== null;
  if (selection) {
    window.document.querySelector('span.vpw-loading-dictionary').remove();
  }
}

function listerner() {
  const myElement = window.document.querySelector('div.enabled');
  if (window.addEventListener && myElement) {
    myElement.addEventListener('DOMSubtreeModified', contentChanged, false);
  }
}

window.document.addEventListener('DOMContentLoaded', () => {
  listerner();
});

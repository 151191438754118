export async function searchInPages(term, limit) {
  try {
    const response = await fetch(
      `/wp-json/wp/v2/search?subtype=page&per_page=${limit}&search=${term}`
    );

    return response.json();
  } catch (error) {
    console.error(error);
    return false;
  }
}

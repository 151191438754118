document.querySelector('.Header-iconMenu').addEventListener('click', (e) => {
  document
    .querySelector('.HeaderMenuFix')
    .classList.toggle('HeaderMenuFix--isActive');
});

document
  .querySelectorAll('.HeaderMenuFix .menu-item-has-children')
  .forEach((submenu) => {
    submenu.addEventListener('click', (e) => {
      e.currentTarget
        .querySelector('.sub-menu')
        .classList.toggle('sub-menu--active');
    });
  });

const parentMenuItens = document.querySelectorAll('.menu-item-has-children');

if (parentMenuItens) {
  const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

  parentMenuItens.forEach((item) => {
    if (supportsTouch) {
      item.querySelector('a').addEventListener('touchstart', (e) => {
        e.preventDefault();
        toogleItems(item);
        submenuPos(item);
      });
    }

    item.addEventListener('mouseenter', () => {
      item.classList.add('active');
      submenuPos(item);
    });
    item.addEventListener('mouseleave', () => {
      item.classList.remove('active');
    });
  });

  const toogleItems = (item) => {
    parentMenuItens.forEach((menuItem) => {
      if (menuItem == item) {
        menuItem.classList.toggle('active');
      } else {
        menuItem.classList.remove('active');
      }
    });
  };

  const submenuPos = (item) => {
    const submenuWrapper = item.querySelector('.sub-menu');
    const menuWrapper = document.querySelector(
      '.Header-menuWrap .menu-menu-cabecalho-container'
    );

    const menuLeftPos = menuWrapper.getBoundingClientRect().left;
    const menuItemPos = item.getBoundingClientRect();

    submenuWrapper.style.left = menuItemPos.left - menuLeftPos + 'px';
  };
}

// chatCss = document.getElementById('chat-css');

function exibirFrame(data) {
  jQuery('#Fab-iframe-chat').html(
    `<iframe src="https://chat.sumicity.com.br/chatclient/chat.php?ci=cliente-site&amp;servico=CHATBOT10353&amp;aplicacao=persona&amp;css='${data}style="display: block;" frameborder="0"></iframe>`
  );
}

// exibirFrame(chatCss.getAttribute('data-css'));

window.exibirFrame = exibirFrame;

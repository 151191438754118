// Contact Components
const contactComponent = GenerateComponent('ContactUsCards__item-title');
const contactBtnComponent = GenerateComponent('ContactUsCards__item-button');
const contactWhatsComponent = GenerateComponent('button-whatsapp');
const contactShortForm = GenerateComponent('shortForm-button');

// buttons Wrappers footer
const socialBtnComponent = GenerateComponent('IconSocial');
const socialBtnComponentMob = GenerateComponent(
  'menu-menu-social-1',
  'id'
)?.children;
const btnApps = GenerateComponent('DownloadButton');

// Phone Components
const telWrapperComponent = GenerateComponent('Footer-tel-number-wrapper');
const telComponent = GenerateComponent('Footer-tel-number');

// user interaction Components
const userInteractionComponent = GenerateComponent('customUserInteraction'); // Custom Classes
const localPageComponent = GenerateComponent('.local-page-list a', 'query');

// header menu Components
const headerMenu = GenerateComponent('header .menu-item a', 'query');
const headerMenuTop = GenerateComponent('Header-itemsRight')[0]?.children;
const subMenu = GenerateComponent('header .sub-menu .menu-item', 'query');
const searchMenu = GenerateComponent('.search-card-list li a', 'query');
const searchComp = GenerateComponent('header-search-form-button');

// plan menu Components
const plansTabList = GenerateComponent('plansController');
const plansTabGigaTvList = GenerateComponent('tabgigatv');
const plansTabStreamingList = GenerateComponent('tab-swiper-wrapper');

// Banner Button Components
const hero = GenerateComponent('Hero--content')[0]?.children;
const middleBanner = GenerateComponent('MiddleBanner-content')[0]?.children;
const bannerButtonCustom = GenerateComponent('bannerButtonCustom'); // Custom Classes

// Component interaction daLayer to plans cards
const cardPlanLink = GenerateComponent('CardLinkData');

function RefactoryDataOptions(data, type = '') {
  let dataRefactory = {
    item_category: 'Internet',
    item_list_name: 'Internet',
    item_list_id: '',
  };

  if (data?.hasTv) {
    dataRefactory = {
      item_category: type,
      item_list_name: data?.streaming?.streamingName,
      item_list_id: data?.streaming?.streamingId,
    };
  }

  return dataRefactory;
}

// function list to plan datalayers
function DataCurrency(data, type) {
  if (data == undefined) return '';
  const dataCat = RefactoryDataOptions(data, type);

  return {
    currency: 'BRL',
    value: data?.price,
    items: [
      {
        item_id: data?.id,
        item_name: data?.name.replace(/<\/?[^>]+(>|$)/g, ""),
        price: data?.price,
        quantity: 1,
        coupon: '',
        ...dataCat,
      },
    ],
  };
}

function DataList(data, isArr, type) {
  if (data == undefined) return '';

  let newData = isArr ? Object.values(data) : data;
  const { item_list_name, item_list_id, item_category } = isArr
    ? RefactoryDataOptions(newData[0], type)
    : RefactoryDataOptions(newData, type);
  i;
  const newDataArr = {
    item_list_id: item_list_id,
    item_list_name: item_list_name,
    items: isArr
      ? newData?.map((item, index) => {
          return {
            item_id: item?.id,
            item_name: item?.name.replace(/<\/?[^>]+(>|$)/g, ""),
            item_category: item_category,
            price: item?.price,
            quantity: 1,
            coupon: '',
          };
        })
      : {},
  };

  return isArr
    ? newDataArr
    : {
        item_list_id: item_list_id,
        item_list_name: item_list_name,
        items: {
          item_id: data?.id,
          item_name: data?.name?.replace(/<\/?[^>]+(>|$)/g, ""),
          item_category: item_category,
          price: data?.price,
          quantity: 1,
          coupon: '',
        },
      };
}

// function view list plan
function ViewList(plan, type) {
  const viewPlanItem = GenerateComponent(plan);
  const objValidation = {};
  objValidation[plan] = [];

  // view 3 primarys items
  function getIndexItems(indexValue) {
    const dataApi = viewPlanItem[indexValue]?.dataset?.plan
      ? JSON.parse(viewPlanItem[indexValue].dataset.plan)
      : {};

    // insert data in dataLayer
    const dataJson = DataCurrency(dataApi, type);

    if (objValidation[plan][indexValue] == true) {
      dataLayer.push({ event: 'view_item', items: dataJson });
      objValidation[plan][indexValue] = false;
    }
  }

  for (let i = 0; i < viewPlanItem.length; i++) {
    objValidation[plan][i] = true;

    viewPlanItem[i].addEventListener('mouseenter', () => {
      getIndexItems(i);
      getIndexItems(i + 1);
      getIndexItems(i + 2);
    });

    viewPlanItem[i].addEventListener('click', ()=>{
      getIndexItems(i)
    })
  }
}

// function view item list plan
function ViewItemList(plan, type, compClick = null) {
  const viewListPlanItem = document.getElementsByClassName(plan);
  for (let i = 0; i < viewListPlanItem.length; i++) {
    const dataApi =
      JSON.parse(viewListPlanItem[i].dataset.plans) !== undefined
        ? JSON.parse(viewListPlanItem[i].dataset.plans)
        : {};

    // insert data dataLayer
    const dataJson = DataList(dataApi, true, type);

    let isView = false;

    viewListPlanItem[i].addEventListener('mouseenter', () => {
      if (!isView) {
        isView = true;
        dataLayer.push({ event: 'view_item_list', items: dataJson });
      }
    });

    if (compClick) {
      compClick[i].addEventListener('click', () => {
        dataLayer.push({ event: 'view_item_list', items: dataJson });
      });
    }
  }
}

// function generate plan
function GenerateLead(btn, form) {
  const generateLeadPlanItem = document.getElementsByClassName(btn);
  const formInputs = GenerateComponent(form);

  for (let i = 0; i < generateLeadPlanItem?.length; i++) {
    const inputs = formInputs[i]?.querySelectorAll('input');
    const leadSrc = GenerateComponent('prince-info')[0]?.innerText;
    const priceValue = GenerateComponent('.prince-container .price', 'query')[0]
      ?.innerText;
    const generateTextElement = GenerateComponent('btn-submit');

    let dataJson = {
      element_text: RemoveCharSpecial(generateTextElement[i]?.innerText),
      currency: 'BRL',
      event: 'generate_lead',
      lead_source: 'Upgrade Velocidade ' + RemoveCharSpecial(leadSrc),
      value: parseFloat(priceValue?.replace('R$ ', '').replace(',', '.')),
    };

    function Verifyinputs() {
      let formValid = true;

      inputs.forEach((input) => {
        if (input.value === '') {
          formValid = false;
        }
      });

      if (formValid) {
        dataLayer.push(dataJson);
      }
    }

    generateLeadPlanItem[i]?.addEventListener('click', () => {
      Verifyinputs();
    });
  }
}

GenerateLead('generateLeadBtn', 'form-lp');

// create reduction and protect components
function GenerateComponent(name, type = 'className') {
  const component = {
    id: document.getElementsByClassName(name),
    className: document.getElementsByClassName(name),
    query: document.querySelectorAll(name),
  };

  return component[type] ? component[type] : null;
}

function UrlValidation(url) {
  const regex = /\/onde-estamos\/(.+)/; // Capture term next 'onde-estamos/'
  const match = url.match(regex);

  if (match) {
    return match[1]; // Return term
  } else {
    return null; // Return null is not term
  }
}

// Generate a simple model dataLayer
function GenerateDataSimple(eventName, elementText) {
  return {
    event: eventName,
    element_text: elementText,
  };
}

// Remove specials characters
function RemoveCharSpecial(text) {
  return text ? text?.replace(/[\n\t]+/g, ' ').trim() : '';
}

// Add dataLayer to contact
function AddDataLayerContact(contactBtn, text = null, isForm = false) {
  const form = document.getElementsByClassName('rd-integration-form')
  if (contactBtn) {
    for (i = 0; i < contactBtn.length; i++) {
      contactBtn[i].addEventListener('click', () => {
        let contactText = RemoveCharSpecial(contactBtn[i].value);
        let validation = true
        const inputs = form[i].querySelectorAll('input')
        if(isForm && inputs){
          for(let j = 0; j < inputs.length; j++ ){
            if(inputs[j].value.length == 0){
              validation = false
            }
          }
        }
        if(validation){
          dataLayer.push({
            event: 'contact',
            element_text: text ? text : contactText,
          })
        }
      }
      );
    }
  }
}

AddDataLayerContact(contactWhatsComponent, 'Whatsapp');
AddDataLayerContact(contactShortForm, null, true);

// Add dataLayer to buttons footer
function AddDataLayerFooter(socialBtn, isChildren = false) {
  if (socialBtn) {
    for (i = 0; i < socialBtn.length; i++) {
      const btnSocial = !isChildren ? socialBtn[i] : socialBtn[i].children[0];

      btnSocial.addEventListener('click', () =>
        dataLayer.push({
          event: 'footer_button',
          element_text: RemoveCharSpecial(btnSocial.innerText),
        })
      );
    }
  }
}

AddDataLayerFooter(socialBtnComponentMob);
AddDataLayerFooter(socialBtnComponent);
AddDataLayerFooter(btnApps, true);

// Add dataLayer to link phone

function AddFooterLink(fLink) {
  if (fLink) {
    for (i = 0; i < fLink.length; i++) {
      fLink[i].addEventListener('click', () =>
        dataLayer.push({
          event: 'footer_link',
          element_text: RemoveCharSpecial(fLink[i]?.innerText),
        })
      );
    }
  }
}

// verify component footer link wrapper
if (telWrapperComponent.length > 0) {
  AddFooterLink(telWrapperComponent);
} else {
  AddFooterLink(telComponent);
}

// Add dataLayer to custom classes buttons
function AddDataInteraction(component) {
  if (component) {
    for (let i = 0; i < component.length; i++) {
      component[i].addEventListener('click', () =>
        dataLayer.push({
          event: 'user_interaction',
          element_text: RemoveCharSpecial(component[i]?.innerText),
        })
      );
    }
  }
}

AddDataInteraction(userInteractionComponent);
AddDataInteraction(localPageComponent);

// Add dataLayer header items
function AddDataHeaderMenu(head, ignoreClass = 'null', customText = null) {
  if (head) {
    for (let i = 0; i < head.length; i++) {
      if (!head[i].classList.contains(ignoreClass)) {
        head[i].addEventListener('click', () =>
          dataLayer.push({
            event: 'header_menu',
            element_text: customText
              ? customText
              : RemoveCharSpecial(head[i]?.innerText),
          })
        );
      }
    }
  }
}

AddDataHeaderMenu(headerMenu);
AddDataHeaderMenu(searchMenu);
AddDataHeaderMenu(headerMenuTop, 'header-search');
AddDataHeaderMenu(searchComp, '', 'Campo de busca');

// Add dataLayer menu plans btn
function AddDataPlanMenuBtn(planBtn, isData, increment = '') {
  if (planBtn) {
    for (let i = 0; i < planBtn.length; i++) {
      for (let j = 0; j < planBtn[i].children.length; j++) {
        planBtn[i]?.children[j]?.addEventListener('click', () =>
          dataLayer.push({
            event: 'plan_menu',
            element_text: isData
              ? increment +
                planBtn[i]?.children[j]?.dataset?.plan?.replace(' plano2', '')
              : increment +
                RemoveCharSpecial(planBtn[i]?.children[j]?.innerText),
          })
        );
      }
    }
  }
}

AddDataPlanMenuBtn(plansTabList);
AddDataPlanMenuBtn(plansTabGigaTvList, false, 'Giga + TV ');
AddDataPlanMenuBtn(plansTabStreamingList, true, 'Internet + ');

// Add datalayer banner Btns
function AddDataBannerBtn(banner) {
  if (banner) {
    for (let i = 0; i < banner.length; i++) {
      if (banner[i].classList.contains('Button')) {
        banner[i].addEventListener('click', () =>
          dataLayer.push({
            event: 'banner_button',
            element_text: RemoveCharSpecial(banner[i].textContent),
          })
        );
      }

      if (banner[i].classList.contains('GridButtons')) {
        const gridButtons = GenerateComponent('GridButtons')[0]?.children;
        AddDataBannerBtn(gridButtons);
      }
    }
  }
}

AddDataBannerBtn(hero);
AddDataBannerBtn(middleBanner);
AddDataBannerBtn(bannerButtonCustom);

// add other events click dataLayer
function AddCustomClickDataLayer(
  comp,
  eventName,
  textCustom = null,
  isForm = false,
  form
) {
  // get Forms
  const itemsValidation = GenerateComponent(form);

  if (comp) {
    for (let i = 0; i < comp.length; i++) {
      // get forms inputs
      const inputs = itemsValidation[i]?.querySelectorAll('input');

      const data = GenerateDataSimple(
        eventName,
        textCustom ? textCustom : RemoveCharSpecial(comp[i].innerText)
      );

      // verify validation inputs
      function Verifyinputs() {
        let formValid = true;
        if (isForm && inputs) {
          inputs.forEach((input) => {
            if (input.value === '') {
              formValid = false;
            }
          });
        }

        if (formValid) {
          dataLayer.push(data);
        }
      }

      comp[i].addEventListener('click', () => Verifyinputs());
    }
  }
}

// function select card plan
function SelectItemData(plan, type) {
  if (plan) {
    for (let i = 0; i < plan.length; i++) {
      let dataApi = {};
      try{
        dataApi = JSON.parse(plan[i]?.dataset?.plan);
      }catch(err){
        
      }

      const dataJson = DataList(dataApi, false, type);

      plan[i].addEventListener('click', () => {
        dataLayer.push({ event: 'select_item', items: dataJson });
      });
    }
  }
}

// Components next loading DOM component
window.addEventListener(
  'load',
  function (event) {
    const location = this.window.location;
    const talkComponent = GenerateComponent('ButtonWhats');
    const talkOtherComponent = GenerateComponent('btn-whats');
    const specialistComponent = GenerateComponent('specialistData');
    const mostDetailsComponent = GenerateComponent(
      'see-more-about-plan-button'
    );
    const channelBtnComponent = GenerateComponent('canais-button');
    const serviceBtnComponent = GenerateComponent('SelfServiceCard__item');
    const wrapperBtnWhereAre = GenerateComponent('ImageWithTopics__content');

    AddCustomClickDataLayer(talkComponent, 'hire_whatsapp');
    AddCustomClickDataLayer(talkOtherComponent, 'hire_whatsapp');
    AddCustomClickDataLayer(
      specialistComponent,
      'talk_to_specialist',
      null,
      true,
      'specialistDataWrapper'
    );
    AddCustomClickDataLayer(
      mostDetailsComponent,
      'card_interaction',
      'Mais detalhes'
    );
    AddCustomClickDataLayer(
      channelBtnComponent,
      'card_interaction',
      'Veja todos os canais'
    );
    AddCustomClickDataLayer(serviceBtnComponent, 'service_button');
    SelectItemData(specialistComponent);
    SelectItemData(talkComponent);
    SelectItemData(talkOtherComponent);

    // Plans Geral
    ViewList('CardPlan');

    // Plans Giga Tv
    ViewItemList('planosgigatv', 'Internet e Giga+ TV');
    ViewList('gigatv-card', 'Internet e Giga+ TV');

    // Plans one Streaming
    ViewItemList('planos', 'Internet + Streaming');

    if (UrlValidation(location?.href)) {
      if (wrapperBtnWhereAre) {
        for (let i = 0; i < wrapperBtnWhereAre?.length; i++) {
          const childrensWhereAre = wrapperBtnWhereAre[i]?.children;
          if (childrensWhereAre) {
            for (let j = 0; j < childrensWhereAre?.length; j++) {
              if (childrensWhereAre[j].classList.contains('Button')) {
                AddDataInteraction([childrensWhereAre[j]]);
              }
            }
          }
        }
      }
    }
  },
  true
);

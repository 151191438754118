import Swiper, { Navigation, Pagination } from 'swiper';

const wrapper = document.querySelector('.swiper-wrapper');

if (wrapper) {
  Swiper.use([Navigation, Pagination]);

  const mutation = new MutationObserver(() => {
    const nextButton = document.querySelector('.swiper-button-next');
    const prevButton = document.querySelector('.swiper-button-prev');
    const styles = wrapper.getAttribute('style');

    if (nextButton && prevButton) {
      if (styles.indexOf('transition-duration: 300ms') === 0) {
        nextButton.style.opacity = '0';
        prevButton.style.opacity = '0';
      } else {
        nextButton.style.opacity = '1';
        prevButton.style.opacity = '1';
      }
    }
  });

  const swiperLoader = () => {
    new Swiper('.StreamingContent-Swiper', {
      slidesPerView: 1,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      spaceBetween: 100,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'bullets',
      },
      breakpoints: {
        960: {
          spaceBetween: 200,
          slidesPerView: 2,
        },
      },
      centeredSlides: true,
    });

    mutation.observe(wrapper, { attributes: true });
  };

  window.addEventListener('load', swiperLoader);
}

import './menu';
import './select';
import './modalLocation';
import './Bills';
import './vlibras/index';
import './Mask';
import './Fab';
import './Chat';
import './SwiperStreaming';
import './form_duvidas';
import './search';
import './Smooth';
import './visibility';
import './dataLayer';
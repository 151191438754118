const phone = document.getElementById('phone_duvidas');
const email = document.getElementById('email_duvidas');
let maxValue = 11;

if (phone && email) {
  phone.addEventListener('keyup', (e) => {
    const cleaned = phone.value.replace(/\D/g, '');
    if (e.target.value.length > maxValue) {
      phone.value = phone.value.slice(0, maxValue);
    } else if (e.target.value.length === 11) {
      const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
      if (match) {
        phone.value = `(${match[1]}) ${match[2]}-${match[3]}`;
        maxValue = 15;
      }
    } else {
      phone.value = cleaned;
    }

    email.value = phone.value;
  });
}

import { searchInPages } from './services/requests';

const loadSearch = () => {
  const search = document.querySelectorAll('.js-search-container');

  if (search) {
    const header = document.querySelector('.Header');
    const searchBtn = document.querySelectorAll('.js-search-button');
    const searchBtnClose = document.querySelectorAll('.js-search-button-close');

    //Add Sugestions
    getStoredSearchs();

    let scrollPos = 0;

    searchBtn.forEach((item) => {
      item.addEventListener('click', () => {
        toogleAllSearch(true, item);
        header.classList.add('search-opened');
        window.addEventListener('scroll', closeSearch);
      });
    });

    searchBtnClose.forEach((item) => {
      item.addEventListener('click', () => {
        closeSearch();
      });
    });

    function closeSearch(event) {
      if (event) {
        if (document.body.getBoundingClientRect().top < scrollPos) {
          toogleAllSearch(false);
          header.classList.remove('search-opened');
          window.removeEventListener('scroll', closeSearch);
        }

        scrollPos = document.body.getBoundingClientRect().top;
      } else {
        toogleAllSearch(false);
        header.classList.remove('search-opened');
      }
    }

    function toogleAllSearch(status, element) {
      if (status) {
        element.closest('.js-search-container').querySelector('input').focus();
        search.forEach((item) => {
          item.classList.add('opened');
        });
      } else {
        search.forEach((item) => {
          item.classList.remove('opened');
        });
      }
    }

    //Efetuando a busca
    const searchForm = document.querySelector('.header-search-form');
    if (searchForm) {
      searchForm.addEventListener('submit', (event) => {
        event.preventDefault();
      });
    }

    const searchInput = document.querySelectorAll('.js-search-input');
    searchInput.forEach((item) => {
      let delayDebounceFn = null;

      item.addEventListener('keyup', (e) => {
        clearTimeout(delayDebounceFn);
        const text = e.currentTarget.value;

        delayDebounceFn = setTimeout(() => {
          executeSearch(text);
        }, 1000);
      });
    });
  }
};

const getStoredSearchs = async () => {
  const storedSearchs = JSON.parse(localStorage.getItem('searchs')) || [];
  if (storedSearchs.length) {
    const resultsBoxes = document.querySelectorAll('.header-search-results');

    resultsBoxes.forEach((resultBox) => {
      const suggestions = resultBox.querySelector('.search-card.suggestions');

      let htmlSearchs =
        '<span class="search-card-title Typography caption left color-grey margin-small"> <b>Você buscou por:</b></span>';
      htmlSearchs += '<ul class="search-card-list">';
      storedSearchs.forEach((item) => {
        htmlSearchs += `<li>`;
        htmlSearchs += `<a href="/busca/?searchTerm=${item}" class="Typography link left color-grey margin-none">${item}</a>`;
        htmlSearchs += `</li>`;
      });
      htmlSearchs += '</ul>';

      suggestions.innerHTML = htmlSearchs;
    });
  }
};

const executeSearch = async (term) => {
  const resultsBoxes = document.querySelectorAll('.header-search-results');
  searchResults('loading');

  if (term !== '') {
    const pages = await searchInPages(term, 7);

    if (pages) {
      let itens = [];

      pages.forEach((page) => {
        const pageTitle = page.title;
        const PageUrl = page.url;

        if (itens.length <= 5 && pageTitle !== 'Busca') {
          if (itens.length <= 4) {
            itens.push({
              label: pageTitle,
              link: `${PageUrl}?searchTerm=${term}`,
            });
          } else {
            itens.push({
              label: 'Mostrar todos os resultados',
              link: `/busca?searchTerm=${term}`,
            });
          }
        }
      });

      if (itens.length > 0) {
        searchResults('content', itens);
        saveTermLocalStorage(term);
      } else {
        searchResults('empty');
      }
    }
  } else {
    searchResults('suggestions');
  }

  function searchResults(status, list) {
    resultsBoxes.forEach((resultBox) => {
      const suggestions = resultBox.querySelector('.search-card.suggestions');
      const empty = resultBox.querySelector('.search-card.empty');
      const content = resultBox.querySelector('.search-card.content');
      const loading = resultBox.querySelector('.search-card.loading');

      // Desabilita todos
      suggestions.classList.add('d-none');
      empty.classList.add('d-none');
      content.classList.add('d-none');
      loading.classList.add('d-none');

      if (status === 'empty') {
        empty.classList.remove('d-none');
      } else if (status === 'content') {
        content.classList.remove('d-none');

        let htmlResults = '<ul class="search-card-list">';
        let className = '';
        list.forEach((item) => {
          if (item.label == 'Mostrar todos os resultados') {
            className = 'bold';
          }

          htmlResults += `<li>`;
          htmlResults += `<a href="${item.link}" class="Typography subtitle-small left color-grey margin-none ${className}">${item.label}</a>`;
          htmlResults += `</li>`;
        });
        htmlResults += '</ul>';

        content.innerHTML = htmlResults;
      } else if (status === 'suggestions') {
        suggestions.classList.remove('d-none');
        content.innerHTML = '';
      } else if (status === 'loading') {
        loading.classList.remove('d-none');
      }
    });
  }

  function saveTermLocalStorage(term) {
    const storedSearchs = JSON.parse(localStorage.getItem('searchs')) || [];

    if (!storedSearchs.includes(term)) {
      if (storedSearchs.length > 5) {
        storedSearchs.splice(0, 1);
      }

      storedSearchs.push(term);

      localStorage.setItem('searchs', JSON.stringify(storedSearchs));
      getStoredSearchs();
    }
  }
};

window.addEventListener('load', loadSearch);

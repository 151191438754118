jQuery(document).ready(function () {
  jQuery('.select').select2({
    placeholder: 'Selecione uma cidade',
    maximumSelectionLength: 1,
    dropdownParent: jQuery('.Modal-loc'),
  });
});

jQuery('.Featured .Plans').parent('').parent('').addClass('Featured-hasPlan');
jQuery(document).on('gform_confirmation_loaded', function (event, formId) {
  jQuery('.gform_confirmation_message').prepend(
    '<span class="Modal-close">×</span><div class="svg-container"> <svg class="checkform" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" aria-hidden="true"> <circle class="circle" fill="#5bb543" cx="24" cy="24" r="22"/> <path class="tick" fill="none" stroke="#FFF" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M14 27l5.917 4.917L34 17"/> </svg></div>'
  );
  jQuery('.gform_confirmation_message').append(
    '<span class="Button Button-center">OK</span>'
  );
  jQuery('.ModalForm-title').addClass('hide');
  jQuery('.Modal-close, .gform_confirmation_message .Button').on(
    'click',
    function () {
      jQuery('.ModalForm').removeClass('ModalForm-show');
    }
  );
});

jQuery(document).ready(function () {
  var textarea = jQuery('textarea');
  const max = jQuery('textarea').attr('maxlength');
  jQuery('.ginput_container_textarea').append(
    '<span class="gform-count">0/' + max + '</span>'
  );

  textarea.keydown(function (event) {
    var numbOfchars = textarea.val();
    var len = Math.min(numbOfchars.length, max);
    jQuery('.gform-count').text(len + '/' + max);
  });
});

/* eslint-disable */
const loadMask = () => {
  function cpfCnpj(v) {
    v = v.replace(/\D/g, '');
    if (v.length <= 11) {
      //CPF
      v = v.replace(/(\d{3})(\d)/, '$1.$2');
      v = v.replace(/(\d{3})(\d)/, '$1.$2');
      v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      //CNPJ
      v = v.replace(/^(\d{2})(\d)/, '$1.$2');
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
      v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
      v = v.replace(/(\d{4})(\d)/, '$1-$2');
    }
    return v;
  }
  document
    .getElementById('cpfCnpj')
    .addEventListener('keyup', ({ target: { value } }) => {
      document.getElementById('cpfCnpj').value = cpfCnpj(value);
    });
};
window.addEventListener('load', loadMask);
